_cruxUtils._cruxLocale = {
"crux.no.bc.message":"P\u0159ehled nen\u00ed k dispozici. Kliknut\u00edm na z\u00e1znam zobraz\u00edte dal\u0161\u00ed podrobnosti",
"crm.appointments.status.markcompleted1": "Ozna\u010dit za dokon\u010den\u00e9",
"crm.mass.actions.all.selected": "<span class=\"dIB vaM\">V\u0161echny</span> <span class='mL3 mR3 crm-font-bold dIB vaM recordCountHt'>{0}\u00a0</span><span class=\"dIB vaM\"> {1} </span>v tomto zobrazen\u00ed byly vybr\u00e1ny.",
"crm.module.selectall": "Vybrat v\u0161e {0} v tomto zobrazen\u00ed",
"records": "z\u00e1znamy",
"crm.record.not.accessible": "z\u00e1znam",
"crm.view.calls.recording.err.msg": "Hlasov\u00fd z\u00e1znam nem\u00e1 p\u0159ehratelnou zvukovou adresu URL.",
"crm.listview.selected.records": "<span class='mL3 crm-small-font-size crm-font-bold vaM dIB'>{0} </span><span class=\"mL3 seletcedRecords\">\u00a0Vybran\u00e9 {1}.</span>",
"totalrecords": "Celkem z\u00e1znam\u016f",
"crm.listview.options.text.clip": "O\u0159\u00edznout text",
"crm.listview.options.text.wrap": "Zalomit text",
"crm.listview.options.reset.width": "Obnovit velikost sloupce",
"crm.listview.options.manage": "Spr\u00e1va sloupc\u016f",
"crm.listview.customview.locked": "Sloupce nelze p\u0159izp\u016fsobit, proto\u017ee je toto zobrazen\u00ed uzam\u010den\u00e9",
"crm.api.apidashboard.SortBy": "T\u0159\u00eddit podle",
"crm.privacy.listview.consent.locked": "Z\u00e1znam byl zablokov\u00e1n pro zpracov\u00e1n\u00ed",
"crm.label.reschedule.call": "P\u0159elo\u017eit {0}",
"crm.button.edit": "Upravit",
"Delete": "Odstranit",
"crm.appointments.status.markcancel": "Zru\u0161it {0}",
"crm.appointments.status.markreschedule": "P\u0159elo\u017eit {0}",
"crm.label.cancel.call": "Zru\u0161it {0}",
"crm.label.mark.completed": "Ozna\u010dit za dokon\u010den\u00e9",
"crm.listview.customview.locked": "Sloupce nelze p\u0159izp\u016fsobit, proto\u017ee je toto zobrazen\u00ed uzam\u010den\u00e9",
"pf.deleted.state": "Odstran\u011bn\u00fd stav",
"crm.project.integration.title": "Zoho Projects",
"crm.integ.sync.message": "Synchronizovat s {0}",
"crm.schedules.prefix.in": "za",
"crm.record.record.locked.other": "Z\u00e1znam je uzam\u010den pro u\u017eivatele s jin\u00fdm profilem.",
"crm.record.lock.record.locked": "Uzam\u010den\u00fd z\u00e1znam.",
"crm.approvalProcess.label.waitingForApproval": "\u010cek\u00e1 na schv\u00e1len\u00ed",
"crm.reviewprocess.record.review.pending": "\u010cek\u00e1 na kontrolu",
"crm.approvalProcess.label.waitingForFindAndMerge": "Pr\u00e1v\u011b prob\u00edh\u00e1 slu\u010dov\u00e1n\u00ed duplik\u00e1t\u016f.",
"crm.privacy.listview.consent.locked": "Z\u00e1znam byl zablokov\u00e1n pro zpracov\u00e1n\u00ed",
"crm.zia.vision.processing": "Zia ov\u011b\u0159uje obr\u00e1zky.",
"crm.zia.vision.rejected.msg": "Z\u00e1znam je odm\u00edtnut z d\u016fvodu chyby v procesu ov\u011b\u0159ov\u00e1n\u00ed obr\u00e1zku.",
"crm.zia.vision.record.failure.msg": "Z\u00e1znam \u010dek\u00e1 na schv\u00e1len\u00ed kv\u016fli chyb\u011b p\u0159i ov\u011b\u0159ov\u00e1n\u00ed obr\u00e1zku.",
"crm.alert.max.cvcols.reached": "M\u016f\u017eete vybrat maxim\u00e1ln\u011b {0} soubor\u016f.",
"crm.label.small.tags": "Zna\u010dky",
"crm.label.edit.module": "Upravit {0}",
"crm.social.integ.no.result": "Nebyly nalezeny \u017e\u00e1dn\u00e9 v\u00fdsledky",
"custommodule.crmfield.fl.name": "N\u00e1zev {0}",
"Appointment": "Sch\u016fzka",
"crm.button.create.call": "Vytvo\u0159it hovor",
"crm.module.create": "Vytvo\u0159it {0}",
"crm.customview.activty.badge": "Odznak aktivity",
"crm.button.callnow": "Zavolejte nyn\u00ed",
"crm.manualcalllist.complete.call": "Protokol hovoru",
"crm.label.schedule.call": "Napl\u00e1nujte hovor",
"crm.filter.label.replied":"odpov\u011bzeno",
"bytes": "byty",
"crm.field.label.user.lookup": "U\u017eivatel",
"crm.translation.refresh.cases": "Akci, kterou se pokou\u0161\u00edte prov\u00e9st, nelze dokon\u010dit. Obnovte str\u00e1nku a zkuste to znovu.",
"crm.project.sync.notes.info": "Budou zobrazeny pouze koment\u00e1\u0159e k \u00fakol\u016fm p\u0159i\u0159azen\u00fdch projekt\u016f",
"crux.note.content.limit.warning.msg":"Povoleno ukl\u00e1dat znaky ({0}) pouze v obsahu pozn\u00e1mek",
"crux.note.formatting.options":"Mo\u017enosti form\u00e1tov\u00e1n\u00ed",
"crm.label.required": "Povinn\u00e9",
"crux.condition.not.belongs.to":"nepat\u0159\u00ed k {0}",
"crm.project.sync.notes.info": "Budou zobrazeny pouze koment\u00e1\u0159e k \u00fakol\u016fm p\u0159i\u0159azen\u00fdch projekt\u016f",
"crm.sf.permission.no.edit": "Nem\u00e1te opr\u00e1vn\u011bn\u00ed aktualizovat polo\u017eky.",
"crm.sf.permission.no.create": "Nem\u00e1te opr\u00e1vn\u011bn\u00ed p\u0159id\u00e1vat polo\u017eky.",
"crm.sf.permission.no.delete": "Nem\u00e1te opr\u00e1vn\u011bn\u00ed odstra\u0148ovat z\u00e1znamy.",
"crux.note.this.note":"tato pozn\u00e1mka",
"KB": "KB",
"MB": "MB",
"current.logged.in.user.role": "Role p\u0159ihl\u00e1\u0161en\u00e9ho u\u017eivatele",
"Unit Price": "Jednotkov\u00e1 cena",
"Qty in Stock": "Mno\u017estv\u00ed na sklad\u011b",
"crm.label.Product Code": "K\u00f3d produktu",
"TotalAfterDiscount": "Celkov\u00e1 \u010d\u00e1stka po slev\u011b",
"crm.lookupfilter.entity.errormsg": "Vybran\u00e9 {0} nespl\u0148uj\u00ed krit\u00e9ria vyhled\u00e1v\u00e1n\u00ed.",
"crm.iamexception.maxlen": "Nem\u016f\u017eete p\u0159idat v\u00edce jak {0} znak\u016f pro {1}.",
"crm.record.locking.permission.denied": "Tuto akci nelze prov\u00e9st, proto\u017ee z\u00e1znam je uzam\u010den.",
"List Price": "Katalogov\u00e1 cena",
"crm.label.no.more.records": "\u017d\u00e1dn\u00e9 dal\u0161\u00ed z\u00e1znamy",
"crm.workflow.rule.created.dateAndTime":"{0} dne {1} v {2}",
"crux.new.note.msg":"\u201e\u010ceho se t\u00fdk\u00e1 tato pozn\u00e1mka?\u201c",
"crm.image.uploaded":"Obr\u00e1zek byl nahr\u00e1n",
"crm.imageupload.drag.drop.here":"Sem p\u0159et\u00e1hn\u011bte obr\u00e1zky",
"crm.fileupload.drag.drop.here":"Sem p\u0159et\u00e1hn\u011bte soubory",
"crm.fileupload.wait.msg":"Po\u010dkejte pros\u00edm, a\u017e se vybran\u00e9 soubory nahraj\u00ed",
"crm.fileupload.attach.fail":"P\u0159ipojen\u00ed p\u0159\u00edlohy se nezda\u0159ilo",
"crm.fileupload.support.format":"Podporovan\u00fd form\u00e1t soubor\u016f (jpeg, jpg, png, pdf) pro \u0161kolen\u00ed Zia",
"custmr.prtl.user.role": "U\u017eivatel port\u00e1lu",
"crm.subform.empty.alert.header":"Pr\u00e1zdn\u00fd \u0159\u00e1dek nelze ulo\u017eit",
"crm.crud.lookup.module.inaccessible": "Nem\u00e1te opr\u00e1vn\u011bn\u00ed k p\u0159\u00edstupu do tohoto modulu vyhled\u00e1v\u00e1n\u00ed",
"crm.crud.lookup.inaccessible.record": "Z\u00e1znam pro v\u00e1s neexistuje nebo je nep\u0159\u00edstupn\u00fd",
"crm.custombutton.valid.weburl.check": "Zadejte hodnotu.",
"crm.label.subform.goto.top": "P\u0159ej\u00edt na za\u010d\u00e1tek seznamu",
"crm.label.subform.row.show.all": "Zobrazit v\u0161e ({0})",
"cpq.pr.nodiscount": "Nebyla p\u0159ipojena \u017e\u00e1dn\u00e1 sleva",
"crm.inventory.lineitem.no.pricebook.new": "Nejsou p\u0159i\u0159azena \u017e\u00e1dn\u00e1 {0}.",
"crm.label.subform.allowedlimit": "Bylo dosa\u017eeno povolen\u00e9ho limitu {0} \u0159\u00e1dk\u016f",
"crm.reports.integ.okay": "OK",
"crm.crud.subform.deleted.record": "Aktualizovan\u00e9 \u0159\u00e1dky podformul\u00e1\u0159e ji\u017e byly odstran\u011bny. Obnovte str\u00e1nku a zkuste to znovu.",
"crux.criteria.fieldlabel.valid.check":"Zadejte platn\u00fd popisek pole",
"crm.subform.delete.confirm.header":"Smazat řádek podformuláře",
"crm.subform.delete.confirm.msg": "Opravdu chcete odstranit vybraný řádek v {0}?",
"crm.subform.delete.tooltip.msg" :"Řádek nemůže být odstraněn. Pro {0} je vyžadován alespoň jeden řádek.",
	"crm.condition.belongs.to":"pat\u0159\u00ed do {0}",//no i18n
"crm.condition.not.belongs.to":"nepat\u0159\u00ed do {0}",//no i18n
"crm.orchestration.error.selectvalue": "Vyberte hodnotu",
	"crm.attach.upload.image":"P\u0159ipojit obr\u00e1zky",//no i18n
"crm.general.select.photo":"Vyberte fotografii",//no i18n
"crm.imageupload.wait.msg":"Po\u010dkejte pros\u00edm, a\u017e se vybran\u00e9 obr\u00e1zky nahraj\u00ed.",//no i18n
"crm.image.filepicker.desc.draganddrop":"P\u0159et\u00e1hn\u011bte obr\u00e1zky.",//no i18n
"crm.image.filepicker.desc.browse":"Klikn\u011bte zde a proch\u00e1zejte obr\u00e1zky...",//no i18n
"crm.attach.upload.userinfo":"Celkov\u00e1 velikost je limitovan\u00e1 na <span class",//no i18n
"crm.image.supported.formats":"Podporovan\u00e9 form\u00e1ty: JPEG, PNG, GIF a BMP.",//no i18n
"crm.imageupload.allowed.field.length":"M\u016f\u017eete nahr\u00e1t pouze maxim\u00e1ln\u011b {0} obr\u00e1zk\u016f.",//no i18n
"crm.general.crop.and.set":"O\u0159\u00edznout a nastavit",//no i18n
"crm.attach.option.label":"P\u0159ipojit",//no i18n
"crm.image.crop.and.rotate":"O\u0159\u00edznout a oto\u010dit",//no i18n
"crm.image.resolution":"\u0158e\u0161en\u00ed",//no i18n
"crm.attachment.size":"Velikost",//no i18n
"crm.label.close":"Zav\u0159\u00edt",//no i18n
"crm.label.previous":"P\u0159edchoz\u00ed",//no i18n
"crm.label.next":"Dal\u0161\u00ed",//no i18n
"crm.subform.record.create.maxrow.limit": "Maxim\u00e1ln\u00ed povolen\u00fd po\u010det z\u00e1znam\u016f {0} v {1}",
"crm.label.subform.addrows": "P\u0159idat \u0159\u00e1dek",
"crm.tax.association.check": "S t\u00edmto produktem nen\u00ed spojena \u017e\u00e1dn\u00e1 da\u0148.",
	"crm.gallery.inventory.template.discount": "Sleva",
	"crm.inventory.discount.scheme.range": "Rozsah",
	"Description": "Popis",
	"crm.inventory.lineitem.no.pricebook": "Nen\u00ed p\u0159i\u0159azen \u017e\u00e1dn\u00fd cen\u00edk.",
	"crm.recordImage.previewimage":"N\u00e1hled obr\u00e1zku",//no i18n
"crm.FileuploadField.addNewImage":"Nahr\u00e1t obr\u00e1zek",//no i18n
"crm.fileuploader.removefile":"Odebrat",//no i18n
	"voc.gc.configure":"Konfigurovat",//no i18n
"Edit":"Upravit",//no i18n
	"crm.criteria.secondaryfield.valid.check" : "Zadejte odpov\u00eddaj\u00edc\u00ed hodnotu pro {0}",
	"crm.label.field":"Pole",//no i18n
"crm.label.value":"Hodnota",//no i18n
	"crm.button.clone":"Klonovat",//no i18n
	"sentiment.criteria.wrongcriteria":"Hodnota krit\u00e9ria nesm\u00ed b\u00fdt vy\u0161\u0161\u00ed ne\u017e {0}",//no i18n
"crm.mb.field.common.splc":"Speci\u00e1ln\u00ed znaky nejsou povoleny. Zadejte platnou hodnotu.",//no i18n
	"crm.view.attachment.download":"St\u00e1hnout",//no i18n
"crm.label.view":"Zobrazit",//no i18n
	"crm.label.field.plural":"pole",//no i18n
	"crm.label.in.minutes":"{0} (v minut\u00e1ch)",//no i18n
	"crm.security.roles.list":"Seznam rol\u00ed",//no i18n
"crm.security.roles.lookup.info":"Vyberte roli ze seznamu.",//no i18n
"crm.territory.addterritory":"P\u0159idat teritorium",//no i18n
"crm.title.edit.territory":"Upravit teritorium",//no i18n
"crm.territory.title.assign.territories":"P\u0159i\u0159adit teritoria",//no i18n
	"crm.label.context.help":"N\u00e1pov\u011bda",//no i18n
	"crm.label.from":"Od",//no i18n
"crm.label.to":"Pro",//no i18n
	"workflow.option.webhookFailure.fromDate":"Od",//no i18n
"workflow.option.webhookFailure.toDate":"Do",//no i18n
"crm.custom.field.less.than.equalto":"{0} mus\u00ed b\u00fdt men\u0161\u00ed nebo rovno {1}.",//no i18n
	"crm.wf.usage.date.criteria.error.msg":"Datum \u201eOd\u201c nesm\u00ed b\u00fdt pozd\u011bj\u0161\u00ed ne\u017e datum \u201eDo\u201c.",//no i18n
	"crm.template.listview.search.no.results":"Nebyly nalezeny \u017e\u00e1dn\u00e9 v\u00fdsledky",//No I18n
	"crm.label.tag.new":"Nov\u00e1 zna\u010dka",//No I18n
	"crm.label.enter.tag":"Zadat zna\u010dky",//No I18n
	"crux.comboBox.max.limit":"Nem\u016f\u017eete vybrat v\u00edce ne\u017e {0} {1}.",//NO I18n
	"Administrator":"Spr\u00e1vce",//No I18n
	"Standard":"Standard",//No I18n
	"crm.button.add":"P\u0159idat",//NO I18n
	"crm.label.users":"U\u017eivatel\u00e9", //NO I18n
  "crm.workflow.alert.roles":"Role", //NO I18n
  "crm.security.groups":"Skupiny", //NO I18n
	"crm.label.available" : "Dostupn\u00e9", //NO I18n
	"crm.label.assign.manually" : "P\u0159i\u0159adit", //NO I18n
	"crm.globalsearch.option.all": "V\u0161e", //NO I18n
	"webform.status.Active":"Aktivn\u00ed", //NO I18n
	"Inactive":"Neaktivn\u00ed", //NO I18n
  "Confirmed":"Potvrzeno", //NO I18n
  "crm.user.component.unconfirmed":"Nepotvrzeno",//no i18n
  "DeletedUser":"Odstran\u011bno", //NO I18n
  "crm.feed.group.admin":"Spr\u00e1vce", //NO I18n
  "crm.ln.lable.current":"Aktu\u00e1ln\u00ed", //NO I18n
	"crm.label.selected": "Vybr\u00e1no",//NO I18n
  "crm.auditlog.user": "U\u017eivatel", //NO I18n
  "cob.role": "Role", //NO I18n
  "zoho.email": "E-mail", //NO I18n
  "Profile": "Profil", //NO I18n
	"crm.security.group.users.empty": "Nebyli nalezeni \u017e\u00e1dn\u00ed u\u017eivatel\u00e9.", //NO I18n
	"crm.label.picklist.none": "Nic", //NO I18n
	"crm.label.notSelected" : "Nevybr\u00e1no",//NO I18n
	"AM" : "Dopo",//NO I18n
	"Call" : "Hovor",//NO I18n
	"crm.phoneNo.Link.Title" : "Vol\u00e1n\u00ed p\u0159es Skype",//NO I18n
	"crm.no.data.found" : "\u017d\u00e1dn\u00fd nalezen\u00fd \u00fadaj",//NO I18n
	"crm.usrpop.non.selected" : "Vybran\u00ed u\u017eivatel\u00e9",//NO I18n
	"crm.zti.label.user": "U\u017eivatelsk\u00e9 jm\u00e9no", //NO I18n
	"crm.label.no.options.found" : "Nebyly nalezeny \u017e\u00e1dn\u00e9 mo\u017enosti",//No I18n
	"crm.globalsearch.search.title" : "Vyhledat",//No I18n
	"None" : "Nic",//No I18n
	"crm.label.criteria.pattern" : "Vzor krit\u00e9ria",//No I18n
	"crm.label.edit.criteria.pattern" : "Upravit vzor",//No I18n
	"criteria.error.alert.brackets.count.mismatch" : "Z\u00e1vorky vzoru se neshoduj\u00ed.",//No I18n
	"criteria.error.alert.brackets.invalid" : "Neplatn\u00e9 z\u00e1vorky okolo oper\u00e1toru podm\u00ednky.",//No I18n
	"crm.criteria.number.notmatch.check" : "Zkontrolujte vzor na {0}.",//No I18n
	"criteria.error.alert.other.params" : "Neplatn\u00fd obsah tohoto vzoru.", //No I18n
	"crm.label.search.for.users": "Vyhledat u\u017eivatele", //NO I18n
	"criteria.error.alert.andor.rowcount.mismatch" : "Vzor krit\u00e9ri\u00ed se neshoduje s vybran\u00fdmi podm\u00ednkami.", //No I18n
	"criteria.error.alert.critnum.rowcount.mismatch" : "Vzor krit\u00e9ri\u00ed se neshoduje s vybran\u00fdmi podm\u00ednkami.", //No I18n
	"and" : "a", //No I18n
	"or" : "nebo", //No I18n
	"crm.label.or" : "NEBO", //No I18n
	"crm.label.and" : "A", //No I18n
	"crm.criteria.fieldlabel.valid.check" : "Zadejte platn\u00fd \u0161t\u00edtek pro \u0159\u00e1dek {0}.", //No I18n
	"crm.criteria.condition.valid.check" : "Zadejte platn\u00e9 podm\u00ednky pro {0}.", //No I18n
	"crm.field.valid.check" : "Zadejte platn\u00fd {0}.", //No I18n
	"crm.alert.label.savepattern" : "Ulo\u017ete vzor p\u0159ed zm\u011bnou krit\u00e9ri\u00ed.",//No I18n
	"crm.criteria.max.rowcnt.exceeds" : "Nem\u016f\u017eete p\u0159idat dal\u0161\u00ed krit\u00e9ria.",//No I18n
	"is" : "je",//No I18n
	"isn\'t" : "nen\u00ed",//No I18n
	"contains" : "obsahuje",//No I18n
	"doesn\'t contain" : "neobsahuje",//No I18n
	"starts with" : "za\u010d\u00edn\u00e1 na",//No I18n
	"ends with" : "kon\u010d\u00ed na",//No I18n
	"is empty" : "je pr\u00e1zdn\u00e9",//No I18n
	"is not empty" : "nen\u00ed pr\u00e1zdn\u00e9",//No I18n
	"is before" : "je p\u0159ed",//No I18n
	"is after" : "je po",//No I18n
	"between" : "mezi",//No I18n
	"not between" : "nikoliv mezi",//No I18n
	"Today" : "Dnes",//No I18n
	"Tommorow" : "Z\u00edtra",//No I18n
	"Tommorow Onwards" : "Za\u010d\u00edn\u00e1me z\u00edtra",//No I18n
	"Yesterday" : "V\u010dera",//No I18n
	"Till Yesterday" : "A\u017e do v\u010derej\u0161ka",//No I18n
	"Last Month" : "Minul\u00fd m\u011bs\u00edc",//No I18n
	"Current Month" : "B\u011b\u017en\u00fd m\u011bs\u00edc", //No I18n
	"Next Month" : "P\u0159\u00ed\u0161t\u00ed m\u011bs\u00edc", //No I18n
	"Last Week" : "Minul\u00fd t\u00fdden", //No I18n
	"Current Week" : "B\u011b\u017en\u00fd t\u00fdden", //No I18n
	"Next Week" : "P\u0159\u00ed\u0161t\u00ed t\u00fdden", //No I18n
	"Age in Days" : "V\u011bk ve dnech", //No I18n
	"Due in Days" : "Term\u00edn ve dnech", //No I18n
	"Scheduled" : "Napl\u00e1nov\u00e1no", //No I18n
	"Attended Dialled" : "P\u0159ijat\u00e9 vyt\u00e1\u010den\u00e9", //No I18n
	"Unattended Dialled" : "Nep\u0159ijat\u00e9 vyt\u00e1\u010den\u00e9", //No I18n
	"Overdue" : "Po term\u00ednu", //No I18n
	"Cancelled" : "Zru\u0161en\u00e9", //No I18n
	"Received" : "P\u0159ijat\u00e9", //No I18n
	"Missed" : "Zme\u0161kan\u00e9", //No I18n
	"crm.alert.character.not.allowed" : "{0} nen\u00ed povoleno", //No I18n
	"crm.condition.in.last" : "v posledn\u00edm", //No I18n
	"crm.zinvoice.dueIn" : "splnit do", //No I18n
	"on" : "V",//No I18n
	"before" : "P\u0159ed",//No I18n
	"crm.label.general.small.after" : "po",//No I18n
	"crm.thisweek" : "Tento t\u00fdden",//No I18n
	"crm.label.this.month" : "Tento m\u011bs\u00edc",//No I18n
	"crm.thisyear" : "Tento rok",//No I18n
	"crm.source.user.and.system" : "U\u017eivatel a syst\u00e9m",//No I18n
	"crm.source.user.or.system" : "U\u017eivatel nebo syst\u00e9m",//No I18n
	"crm.label.system2" : "Syst\u00e9m",//No I18n
	"crm.source.user.only" : "Pouze u\u017eivatelem",//No I18n
	"crm.source.system.only" : "Pouze syst\u00e9mem",//No I18n
	"crm.condition.till.today" : "Do dne\u0161ka",//No I18n
	"game.month.left" : "1 m\u011bs\u00edc",//No I18n
	"game.months.left" : "{0} m\u011bs\u00edc\u016f",//No I18n
	"crm.condition.last.30.days" : "za posledn\u00edch 30 dn\u00ed",//No I18n
	"crm.condition.last.60.days" : "za posledn\u00edch 60 dn\u00ed",//No I18n
	"crm.condition.last.90.days" : "za posledn\u00edch 90 dn\u00ed",//No I18n
	"crm.label.filter.typehere" : "Pi\u0161te sem", //No I18N
	"crm.filter.is.not" : "nen\u00ed", //No I18n
	"crm.condition.until.now" : "Dosud",//No I18n
	"crm.filter.email.isblocked" : "is blocked",//No I18n
	"crm.filter.email.isnotblocked" : "is not blocked",//No I18n
	"crm.label.no.results.match" : "\u017d\u00e1dn\u00e1 shoda v\u00fdsledk\u016f",//No I18n
	"crm.label.select.user" : "Klikn\u011bte pro v\u00fdb\u011br u\u017eivatel\u016f.", //No I18n
	"current.logged.in.user": "P\u0159ihl\u00e1\u0161en k u\u017eivateli", //NO I18n
	"current.logged.in.user.definition": "U\u017eivatel, kter\u00fd inicializuje akci z\u00e1znamu.", //NO i18n
	"crm.security.group": "Skupina", //NO I18n
	"crm.security.role": "Role", //NO I18n
	"Date" : "Datum",//No I18n
	"crm.field.valid.decimal.check2" : "Po\u010det desetinn\u00fdch m\u00edst pro pole <i>{0}</i> mus\u00ed b\u00fdt men\u0161\u00ed nebo roven {1}.",//No I18n
	"crm.field.empty.check" : "{0} nem\u016f\u017ee b\u00fdt pr\u00e1zdn\u00e9.",//No I18n
	"crm.label.add.note": "P\u0159idat pozn\u00e1mku", //NO I18n
	"crm.label.simply.by": "podle", //NO I18n
	"crm.general.addnote": "P\u0159idat pozn\u00e1mku", //NO I18n
	"crm.general.addtitle": "P\u0159idat titul", //NO I18n
	"crm.label.attach.file": "P\u0159ipojit soubor", //NO I18N
	"crm.button.cancel": "Zru\u0161it", //NO I18N
	"crm.button.save": "Ulo\u017eit", //NO I18N
	"crm.button.mass.delete": "Odstranit", //NO I18N
	"crm.warning.delete.record": "Opravdu chcete odstranit \u201e{0}\u201c?", //NO I18N
	"crm.label.yes": "Ano", //NO I18N
	"crm.note.view.previous": "Zobrazit p\u0159edchoz\u00ed pozn\u00e1mky", //NO I18N
  "of": "z", //NO I18N
	"crm.label.notes": "Pozn\u00e1mky", //NO I18N
	"crm.note.recent.first": "Ned\u00e1vn\u00e9 prvn\u00ed", //NO I18N
	"crm.note.recent.last": "Ned\u00e1vn\u00e9 posledn\u00ed", //NO I18N
	"crm.territory.label.only": "pouze {0}", //no i18n
	"crm.select" : "Vyberte",//No I18n
	"crm.button.apply.filter" : "Pou\u017e\u00edt filtr",//No I18n
	"crm.alert.maximum.text.values.contains" : "Proto toto pole nem\u016f\u017eete {0} zadat v\u00edc hodnot.",//No I18n
	"PM" : "Odpo",//No I18n
	//start-cx create form keys
	"crm.duplicate.value.not.allowed": "Duplicitn\u00ed hodnoty nejsou povoleny.",//no i18n
	"crm.duplicate.value.available": "Byl nalezen {0} se stejnou {1}.",//no i18n
	"crm.duplicate.value.available.multiple": "Byl nalezen v\u00edce ne\u017e jeden {0} se stejnou {1}.",//no i18n
	"crm.custombutton.nobuttons.found": "Nebylo nalezeno \u017e\u00e1dn\u00e9 tla\u010d\u00edtko",//no i18n
	"crm.custombutton.create.newbutton": "Vytvo\u0159it tla\u010d\u00edtko",//no i18n
	"crm.custombutton.manage.button": "Spravovat tla\u010d\u00edtka",//no i18n
	"crm.custombutton.name": "N\u00e1zev",//no i18n
	"crm.customize.custombutton.function.desc": "Popis",//no i18n
	"crm.custombutton.action": "\u010cinnost tla\u010d\u00edtka",//no i18n
	"crm.custombutton.empty.field.values": "Pro n\u00e1sleduj\u00edc\u00ed pole byla nalezena pr\u00e1zdn\u00e1 hodnota:",//no i18n
	"crm.custombutton.proceed.action": "Opravdu chcete pokra\u010dovat?",//no i18n
	"zb.common.Cancel": "Zru\u0161it",//no i18n
	"crm.yes.proceed": "Ano, pokra\u010dovat",//no i18n
	"crm.label.module.merge": "Slou\u010dit {0}",//no i18n
	"crm.view.record": "Zobrazit {0}",//no i18n
	"crm.tpi.ctiapi.config.choosefile": "Vyberte soubor",//no i18n
	"crm.label.subform.addrows": "P\u0159idat \u0159\u00e1dek",//no i18n
	"crm.button.save&new": "Ulo\u017eit a nov\u00e9",//no i18n
	//end-cx create form keys
	"Jan" : "Leden",//No I18n
	"Feb" : "\u00danor",//No I18n
	"Mar" : "B\u0159ezen",//No I18n
	"Apr" : "Duben",//No I18n
	"Jun" : "\u010cerven",//No I18n
	"Jul" : "\u010cervenec",//No I18n
	"Aug" : "Srpen",//No I18n
	"Sep" : "Z\u00e1\u0159\u00ed",//No I18n
	"Oct" : "\u0158\u00edjen",//No I18n
	"Nov" : "Listopad",//No I18n
	"Dec" : "Prosinec",//No I18n
	"crm.mb.newversion.msg4":"OK, rozum\u00edm!", //no i18n
	"crm.label.More" :"V\u00edce", //no i18n

	 "crm.label.unmapped.stages":"Nez\u00fa\u010dtovan\u00e9", //no i18n
	 "crm.wf.summary.label.ShowInstantActions" : "Zobrazit v\u00edc",//No I18n
	 "crm.wf.summary.label.HideInstantActions" : "Zobrazit m\u00e9n\u011b",//No I18n

	 //filter related keys-start
	"crm.inv.mail.time.two.days.subject":"{0} - {1}",//no i18n
	"crm.lead.prediction.tooltip.convert":"PRAVD\u011aPODOBNOST",//no i18n
	"crm.lead.prediction.tooltip.score":"SK\u00d3RE",//no i18n
	"Planned":"Pl\u00e1nov\u00e1no",//no i18n
	"Invited":"Pozvan\u00ed",//no i18n
	"Sent":"Odesl\u00e1no",//no i18n
	"Received":"P\u0159ijat\u00e9",//no i18n
	"Opened":"Otev\u0159eno",//no i18n
	"Responded":"Odpov\u011bzeno",//no i18n
	"Bounced":"Nep\u0159ijato",//no i18n
	"Opted\ Out":"Odm\u00edtnuto",//no i18n
	"crm.filter.label.with.open":"S otev\u0159en\u00fdmi {0}",//no i18n
	"crm.filter.label.without.open":"Bez otev\u0159en\u00fdch {0}",//no i18n
	"crm.filter.label.without.any":"Bez jak\u00fdchkoliv {0}",//no i18n
	"crm.filter.label.with.module":"S {0}",//no i18n
	"crm.filter.label.without.open":"Bez otev\u0159en\u00fdch {0}",//no i18n
	"crm.filter.label.activity.due":"{0} ke spln\u011bn\u00ed",//no i18n
	"crm.filter.label.activity.done":"{0} hotovo",//no i18n
	"Notes":"Pozn\u00e1mky",//no i18n
	"crm.filter.label.notes.added":"Pozn\u00e1mky p\u0159id\u00e1ny",//no i18n
	"crm.label.filter.email.status":"Stav posledn\u00edho e-mailu",//no i18n
	"crm.label.filter.email.clicked":"kliknuto",//no i18n
	"crm.label.filter.email.responded":"zodpov\u011bzeno",  //no i18n
	"crm.label.filter.email.info":"Filtrovat z\u00e1znamy na z\u00e1klad\u011b posledn\u00edho stavu odeslan\u00fdch/p\u0159ijat\u00fdch e-mail\u016f.",//no i18n
	"crm.filter.label.sent":"odesl\u00e1no",//no i18n
	"crm.filter.label.not.sent":"Neodesl\u00e1no",//no i18n
	"crm.filter.label.opened":"otev\u0159eno",//no i18n
	"crm.filter.label.not.opened":"neotev\u0159eno",//no i18n
	"crm.filter.label.received":"p\u0159ijato",//no i18n
	"crm.filter.label.not.received":"nep\u0159ijato",//no i18n
	"crm.filter.label.bounced":"odm\u00edtnuto",//no i18n
	"crm.filter.label.opened.not.replied":"otev\u0159en\u00e9, ale ponechan\u00e9 bez odpov\u011bdi", //no i18n
	"crm.filter.label.any":"Libovoln\u00e9 z v\u00fd\u0161e uveden\u00fdch",//no i18n
	"crm.zia.config.potential.amount":"{0} \u010c\u00e1stka",//no i18n
	"Quote\ Stage":"F\u00e1ze {0}",//no i18n
	"crm.module.owner":"Vlastn\u00edk {0}",//no i18n
	"Potential\ Closing\ Date":"Datum uz\u00e1v\u011brky {0}",//no i18n
	"crm.lead.prediction.likely.convert":"Pravd\u011bpodobnost konverze",//no i18n
	"crm.lead.prediction.convert.high":"Vysok\u00e9",//no i18n
	"crm.lead.prediction.convert.medium":"St\u0159edn\u00ed",//no i18n
	"crm.lead.prediction.convert.low":"N\u00edzk\u00e9",//no i18n
	"crm.predictions.feature.label":"P\u0159edpov\u011b\u010f",//no i18n
	"crm.intelligence.prediction.likelywin":"pravd\u011bpodobnost v\u00fdhry",//no i18n
	"crm.intelligence.prediction.likelylose":"pravd\u011bpodobnost ztr\u00e1ty",//no i18n
	"crm.intelligence.prediction.halfchance":"Pravd\u011bpodobnost 50:50",//no i18n
	"crm.intelligence.prediction.score":"Sk\u00f3re predikce",//no i18n
	"crm.lead.prediction.recent.score":"Sk\u00f3re predikce z ned\u00e1vn\u00e9 doby",//no i18n
	"crm.intelligence.prediction.lastconv":"posledn\u00ed 3 konverzace",//no i18n
	"crm.intelligence.prediction.recordsfocus":"Z\u00e1znamy, na kter\u00e9 je t\u0159eba se zam\u011b\u0159it",//no i18n
	"crm.intelligence.prediction.slowmoving":"Pomal\u00fd pohyb",//no i18n
	"crm.intelligence.prediction.trend.down":"Pokles trendu v posledn\u00ed dob\u011b",//no i18n
	"crm.label.touched.records":"Dot\u010den\u00e9 z\u00e1znamy",//no i18n
	"crm.label.untouched.records":"Nedot\u010den\u00e9 z\u00e1znamy",//no i18n
	"crm.label.record.action":"Z\u00e1znam akce",//no i18n
	"workflow.rule.view.label.Modified":"Upraveno",//no i18n
	"crm.label.not.modified":"Nezm\u011bn\u011bno",//no i18n
	"crm.label.related.records.action":"Souvisej\u00edc\u00ed \u010dinnost z\u00e1znam\u016f",//no i18n
	"Done":"Hotovo",//no i18n
	"crm.label.not.done":"Nen\u00ed hotovo",//no i18n
	"sentiment.model":"Zabarven\u00ed e-mailu",//no i18n
	"sentiment.criteria.count":"Po\u010det",//no i18n
	"sentiment.criteria.percentage":"Procenta",//no i18n
	"sentiment.criteria.lastmail":"Pro posledn\u00ed email",//no i18n
	"Chats":"Chaty",//no i18n
	"Attended":"P\u0159ijat\u00e9",//no i18n
	"crm.lead.prediction.popup.text":"Kdy\u017e je pravd\u011bpodobnost konverze {0}, m\u011blo by se sk\u00f3re predikce pohybovat mezi {1}.",//no i18n
	"crm.lead.prediction.popup.final":"Zm\u011b\u0148te odpov\u00eddaj\u00edc\u00edm zp\u016fsobem filtr a opakujte akci.",//no i18n
	"crm.custom.field.less.than.to1":"Rozsah Od nem\u016f\u017ee b\u00fdt v\u011bt\u0161\u00ed ne\u017e rozsah Do.",//no i18n
	"Last\ Activity\ Date":"Datum posledn\u00ed aktivity",//no i18n
	"crm.label.vendor.name":"N\u00e1zev {0}",//no i18n
	"hours":"Hod.",//no i18n
	"days":"dn\u00ed",//no i18n
	"weeks":"t\u00fddn\u016f",//no i18n
	"months":"m\u011bs\u00edc\u016f",//no i18n
	"years":"roky",//no i18n
	"crm.label.general.small.after":"po",//no i18n
	"Last\ Week":"Minul\u00fd t\u00fdden",//no i18n
	"Last\ Month":"Minul\u00fd m\u011bs\u00edc",//no i18n
	"crm.module.name":"{0} N\u00e1zev",//no i18n
	"Campaign":"Kampa\u0148",//no i18n
	"Tasks":"\u00dakoly",//no i18n
	"Calls":"Hovory",//no i18n
	"Events":"Ud\u00e1losti",//no i18n
	"sentiment.criteria.wrongcriteria":"Hodnota krit\u00e9ria nesm\u00ed b\u00fdt vy\u0161\u0161\u00ed ne\u017e {0}",//no i18n
	"crm.chosen.minimum.input.text":"Zadejte {0} nebo v\u00edce znak\u016f",//no i18n
	"crm.intelligence.prediction.trendup":"Vzestupn\u00e1 tendence",//no i18n
	"crm.intelligence.prediction.trenddown":"Sestupn\u00e1 tendence",//no i18n
	"crm.zia.prediction.notrend":"No trend",//no i18n
	"crm.zia.prediction.likelihood":"Likelihood Score",//no i18n
	"Completed":"Dokon\u010den\u00e9" ,//no i18n
	"crm.label.success":"\u00dasp\u011bch" ,//no i18n
	"crm.label.Failure":"Chyba" ,//no i18n
	"Both":"Oboj\u00ed" ,//no i18n
	"crm.condition.cannot.empty":"Podm\u00ednka nem\u016f\u017ee b\u00fdt pr\u00e1zdn\u00e1.",//no i18n
	"crm.condition.last.30.days":"za posledn\u00edch 30 dn\u00ed",//no i18n
	"crm.condition.last.60.days":"za posledn\u00edch 60 dn\u00ed",//no i18n
	"crm.condition.last.90.days":"za posledn\u00edch 90 dn\u00ed",//no i18n
	"crm.sentiment.Positive":"Pozitivn\u00ed",//no i18n
	"crm.sentiment.Negative":"Negativn\u00ed",//no i18n
	"sentiment.positiveandnegative":"Kladn\u00e9 a z\u00e1porn\u00e9",//no i18n
	"sentiment.positiveornegative":"Kladn\u00e9 nebo z\u00e1porn\u00e9",//no i18n
	"sentiment.positiveonly":"Pouze kladn\u00e9",//no i18n
	"sentiment.negativeonly":"Pouze z\u00e1porn\u00e9",//no i18n
	"crm.sentiment.Neutral":"Neutr\u00e1ln\u00ed",//no i18n
	"crm.filters.select.campaign.type":"Vyberte typ {0}",//no i18n
	"crm.filters.select.campaign.status":"Vyberte stav {0}",//no i18n
	"campaign.Member" : "\u010clen",//no i18n
	"Service":"Slu\u017eba",//no i18n
	"Activities":"\u010cinnosti",//no i18n
	"crm.livedesk.pot.nextdays":"Dal\u0161\u00edch {0} dn\u016f",//no i18n
	"Today\ +\ Overdue":"Dne\u0161n\u00ed + po term\u00ednu",//no i18n
	"crm.source.user.and.system":"U\u017eivatel a syst\u00e9m",//no i18n
	"crm.source.user.or.system":"U\u017eivatel nebo syst\u00e9m",//no i18n
	"User":"U\u017eivatel",//no i18n
	"crm.source.user.only":"Pouze u\u017eivatelem",//no i18n
	"crm.source.system.only":"Pouze syst\u00e9mem",//no i18n
	"Scheduled":"Napl\u00e1nov\u00e1no",//no i18n
	"Attended\ Dialled":"P\u0159ijat\u00e9 vyt\u00e1\u010den\u00e9",//no i18n
	"Unattended\ Dialled":"Nep\u0159ijat\u00e9 vyt\u00e1\u010den\u00e9",//no i18n
	"Cancelled":"Zru\u0161en\u00e9",//no i18n
	"crm.filter.email.isblocked":"je blokov\u00e1no",//no i18n
	"crm.filter.email.isnotblocked":"Nen\u00ed blokov\u00e1no",//no i18n
	"condition.till.now":"Dosud",//no i18n
	"crm.recurring.no.months":"{0} m\u011bs\u00edc\u016f",//no i18n
	"crm.lead.prediction.tooltip":"Pravd\u011bpodobnost konverze - rozsah sk\u00f3re",//no i18n
	"crm.website.activity":"Aktivita webu",//no i18n
	"crm.label.By":"podle",//no i18n
	"crm.chosen.searching.text":"Hled\u00e1m...",//no i18n
	"crm.label.memberstatus.is":"a stav \u010dlena je",//no i18n
	"crm.events.duration":"Trv\u00e1n\u00ed",//no i18n
	"crm.title.clear.name":"Vy\u010distit",//no i18n
	"crm.label.status.is":"a stav je",//no i18n
	"zia.last3.help":"Konverzace zahrnuj\u00ed hovory, \u00fakoly, {0}, p\u0159ijat\u00e9 e-maily, pozn\u00e1mky, n\u00e1v\u0161t\u011bvy, koment\u00e1\u0159e na soci\u00e1ln\u00edch s\u00edt\u00edch a po\u017eadavky na podporu z\u00e1kaznick\u00e9ho odd\u011blen\u00ed.",//no i18n
	"crm.label.tag.related.to":"souvisej\u00edc\u00ed s",//no i18n
	//filter related keys-End
	"crm.label.account.created" : "Bude vytvo\u0159ena nov\u00e1 {0}.",//No I18n
	"crm.krp.no.records.found" : "Nebyl nalezen \u017e\u00e1dn\u00fd {0}.",//No I18n
	"crm.module.new" : "Nov\u00fd {0}",//No I18n
	"crm.label.view" : "Zobrazit",//No I18n
	"crm.nsocial.customers" : "Z\u00e1kazn\u00edci",//No I18n
	"crm.nsocial.open.potential" : "Otev\u0159eno {0}",//No I18n
	"crm.nsocial.lead.contact" : "{0}/{1}",//No i18n
	"Others" : "Jin\u00e9",//No i18n
	"crm.field.length.check" : "Hodnota {0} p\u0159esahuje maxim\u00e1ln\u00ed d\u00e9lku.", //No I18n
	"crm.lower.now":"nyn\u00ed",//no i18n
	"crm.time.min.ago":"P\u0159ed {0} minutami",//no i18n
	"crm.time.mins.ago":"P\u0159ed {0} minutami",//no i18n
	"crm.time.hr.ago":"P\u0159ed {0} hodinami",//no i18n
	"crm.time.hrs.ago":"P\u0159ed {0} hodinami", //no i18n
	"AllUsers": "V\u0161ichni u\u017eivatel\u00e9", //no i18n
	"crm.label.search":"Vyhledat",//no i18n
	"crm.api.filterby":"Filtrovat podle",//no i18n
	"crm.customview.nofields.found":"--\u017d\u00e1dn\u00e1 shodn\u00e1 pole--",//no i18n
	"crm.setup.system.ziarecommendation":"Doporu\u010den\u00ed Zia",//no i18n
	"crm.reviewprocess.smart.filter":"Stav z\u00e1znamu procesu kontroly",//no i18n
	"crm.dashboard.sharing.empty.value.alert":"Zvolte hodnotu.",//no i18n
	"crm.cal.custom":"Vlastn\u00ed",//no i18n
	"crm.mb.field.common.empt":"Hodnota nem\u016f\u017ee b\u00fdt pr\u00e1zdn\u00e1.",//no i18n
	"crm.chosen.error.loading.text":"Bohu\u017eel se n\u00e1m nepoda\u0159ilo na\u010d\u00edst va\u0161e v\u00fdsledky.",//no i18n
	"crm.mxnlookup.select" : "P\u0159i\u0159adit {0}",//No I18n
	"crm.lookup.chooserecord":"Vyberte {0}",//no i18n
	"crm.record.selected":"Vybran\u00e9 {0}",//no i18n
	"crm.module.empty.message" : "Nebyl nalezen \u017e\u00e1dn\u00fd {0}.",//No I18n
	"crm.mxnlookup.selected" : "P\u0159i\u0159azeno {0}",//No I18n
	"crm.security.error" : "Nem\u00e1te dostate\u010dn\u00e1 opr\u00e1vn\u011bn\u00ed k proveden\u00ed t\u00e9to operace. Kontaktujte sv\u00e9ho spr\u00e1vce syst\u00e9mu.", //no i18n
	"crm.label.creator.noPermission" : "Opr\u00e1vn\u011bn\u00ed zam\u00edtnuto", //no i18n
	"crm.smartfilter.related.module.msg" : "Nem\u016f\u017eete vybrat v\u00edc ne\u017e t\u0159i souvisej\u00edc\u00ed moduly.", //no i18n
	"crm.smartfilter.related.module.msg1" : "(Nap\u0159.: Email, \u010dinnosti, pozn\u00e1mky)", //no i18n
	"crm.smartfilter.related.module.msg2" : "Trv\u00e1n\u00ed nem\u016f\u017ee b\u00fdt pr\u00e1zdn\u00e9", //no i18n
	"crm.label.timeZone": "\u010casov\u00e9 p\u00e1smo", //NO I18n
	"crm.label.insufficient.privileges": "Nedostate\u010dn\u00e1 opr\u00e1vn\u011bn\u00ed pro proveden\u00ed t\u00e9to operace. Kontaktujte sv\u00e9ho spr\u00e1vce.", //NO I18n
	"crm.filter.header.secton.system": "Filtry definovan\u00e9 syst\u00e9mem", //NO I18N
	"crm.filter.header.secton.fields": "Filtrovat podle pol\u00ed", //NO I18N
	"crm.createfield.calcinfo.new" : "Toto pole funguje jako kalkula\u010dka pro v\u0161echny zadan\u00e9 v\u00fdrazy.</br> <b>Nap\u0159. 20+20</b> vyd\u00e1 automaticky <b>40</b>",//No i18n
	"crm.lable.read.only" : "Pole jen pro \u010dten\u00ed",//No i18n
	"crm.column.sort.asc" : "Vzestupn\u011b",//No I18n
	"crm.column.sort.desc" : "Sestupn\u011b",//No i18n
	"crm.column.unsort" : "Zru\u0161it \u0159azen\u00ed",//No I18n
	"custmr.prtl.notes.shr.to.custmr": "Sd\u00edlet se z\u00e1kazn\u00edkem", //NO I18N
	"crm.label.edited":"Upraveno",//no i18n
	"crm.label.edited.on":"Upraveno",//no i18n
	"crm.message.limit.exceed": "Pouze {0} znak\u016f je povoleno pro {1}.", //NO I18N
	"custmr.prtl.notes.shrd.with.custmr": "Sd\u00edlen\u00e9 se Z\u00e1kazn\u00edkem", //NO I18N
	"crm.button.ok" : "OK", //NO I18N
	"crm.role.already.selected": "Tato role je ji\u017e vybr\u00e1na", //no i18n
	"crm.user.deleted": "U\u017dIVATEL BYL ODSTRAN\u011aN",  //NO I18N
	"crm.account.closed": "TENTO \u00da\u010cET BYL UZAV\u0158EN",  //NO I18N
	"crm.start.chat": "Zah\u00e1jit chat",  //NO I18N
	"crm.start.call": "Spustit hovor",  //NO I18N
	"crm.recipient.invalid.email" : "Byly nalezeny neplatn\u00e9 e-maily.", //NO I18N
	"crm.recipient.add.recipient" : "P\u0159idat dal\u0161\u00edho p\u0159\u00edjemce", //NO I18N
	"crm.start.video.call": "Spustit videohovor",  //NO I18N //ignorei18n_start
	"Score":"Hodnocen\u00ed",
	"Positive Score":"Pozitivn\u00ed hodnocen\u00ed",
	"Negative Score":"Negativn\u00ed hodnocen\u00ed",
	"Touch Point Score":"Hodnocen\u00ed kontaktn\u00edho bodu",
	"Positive Touch Point Score":"Pozitivn\u00ed hodnocen\u00ed kontaktn\u00edho bodu",
	"Negative Touch Point Score":"Negativn\u00ed hodnocen\u00ed kontaktn\u00edho bodu",
	"crm.label.scoring.rules":"Pravidla hodnocen\u00ed",
	"is\ OPEN":"je OTEV\u0158EN\u00c1",//no i18n
	"is\ WON":"je VYHRAN\u00c1",//no i18n
	"is\ LOST":"je ZTRACEN\u00c1",//no i18n
	"crm.potential.all.open":"V\u0161echny otev\u0159en\u00e9 f\u00e1ze",//no i18n
	"crm.potential.all.won":"V\u0161echny zav\u0159en\u00e9 vyhran\u00e9 f\u00e1ze",//no i18n
	"crm.potential.all.lost":"V\u0161echny zav\u0159en\u00e9 ztracen\u00e9 f\u00e1ze",//no i18n
	"crm.label.type.minutes": "Zapi\u0161te zde v minut\u00e1ch", //NO I18N

	"crm.campaign.member.status" : "Stav \u010dlena",//no i18n
	"crm.dashboard.select.type" : "Vybrat {0}",//no i18n
	"crm.campaign.service.status":"Stav slu\u017eby",//no i18n

	"crm.label.addColumn":"P\u0159idat sloupec",//no i18n
	"crm.button.clear.filter":"Uzav\u0159en\u00ed filtru",//no i18n
	"crm.button.show.filter":"Zobrazen\u00ed filtru",//no i18n
	"crm.las.error.user.maxlimit":"M\u016f\u017eete vybrat maxim\u00e1ln\u011b 20 u\u017eivatel\u016f.",//no i18n
	"crm.las.error.picklist.maxlimit":"M\u016f\u017eete vybrat maxim\u00e1ln\u011b 20 mo\u017enost\u00ed.",//no i18n

	"crm.fileuploader.message.responseerror": "Nahr\u00e1v\u00e1n\u00ed selhalo.", //NO I18N
	"crm.storage.create.error":"Nelze vytvo\u0159it \u017e\u00e1dn\u00e9 nov\u00e9 z\u00e1znamy, proto\u017ee jste dos\u00e1hli maxim\u00e1ln\u00edho limitu kapacity \u00falo\u017ei\u0161t\u011b dat.",//no i18n
	"crm.storage.create.error.client":"Nelze vytvo\u0159it \u017e\u00e1dn\u00e9 nov\u00e9 z\u00e1znamy, proto\u017ee v\u00e1\u0161 spr\u00e1vce dos\u00e1hl maxim\u00e1ln\u00edho limitu \u00falo\u017ei\u0161t\u011b. Pro vy\u0159e\u0161en\u00ed tohoto probl\u00e9mu kontaktujte {0}.",//no i18n
	"crm.storage.avail.info":"({0} zbytek z {1})",//no i18n
	"crm.storage.error.key.manage":"Spravujte sv\u00e9 \u00falo\u017ei\u0161t\u011b dat",//no i18n
	"Records":"Z\u00e1znamy",//no i18n
	"crm.workflow.alert.additional.recipients" : "Dal\u0161\u00ed p\u0159\u00edjemci", //NO I18N
	"crm.workflow.alert.type.otherEmails" : "Jednotliv\u00e9 e-mailov\u00e9 adresy odd\u011blte \u010d\u00e1rkami.", //NO I18N
	"crm.related.contact.account" : "{0} Souvisej\u00edc\u00ed s {1}",//No I18n
	"crm.allcontact.show" : "V\u0161echny {0}",//No I18n
	"crm.button.mass.show" : "Uk\u00e1zat",//No I18n
	"crm.msg.custom.view.not.replied" : "Nezodpov\u011bzen\u00e9 zpr\u00e1vy", //NO I18N
	"crm.msg.custom.view.replied" : "Zodpov\u011bzen\u00e9 zpr\u00e1vy",//NO I18N
	"crm.workflow.select.recipients" : "P\u0159\u00edjemci", //NO I18N
	"crm.custom.module.no.profile.selected.alert1":"Vyberte alespo\u0148 jeden profil.",//NO I18N
	"crm.auto.enrich.remove.default.profile" : "Litujeme, ale nem\u016f\u017eete odebrat v\u00fdchoz\u00ed profil.",//NO I18N
	"crm.inv.label.add.emails" : "P\u0159idat e-maily", //NO I18N
	"crm.prediction.analytics.filter.year":"Minul\u00fd rok",//no i18n
	"Previous\ FY":"P\u0159edchoz\u00ed fisk\u00e1ln\u00ed rok",//no i18n
	"Current\ FY":"B\u011b\u017en\u00fd fisk\u00e1ln\u00ed rok",//no i18n
	"Next\ FY":"P\u0159\u00ed\u0161t\u00ed fisk\u00e1ln\u00ed rok",//no i18n
	"Current\ FQ":"B\u011b\u017en\u00e9 fisk\u00e1ln\u00ed \u010dtvrtlet\u00ed",//no i18n
	"Next\ FQ":"P\u0159\u00ed\u0161t\u00ed fisk\u00e1ln\u00ed \u010dtvrtlet\u00ed",//no i18n
	"Previous\ FQ":"P\u0159edchoz\u00ed fisk\u00e1ln\u00ed \u010dtvrtlet\u00ed",//no i18n
	"crm.picklist.sample.text":"Vzorov\u00fd text",//no i18n
	"crm.more.colors":"Dal\u0161\u00ed barvy",//no i18n
	"crm.button.back.alone":"Zp\u011bt",//no i18n
	"crm.field.label.email":"E-mail",//no i18n
"crm.zia.nba.feature.label":"P\u0159\u00ed\u0161t\u00ed nejlep\u0161\u00ed akce",//no i18n
"Meeting":"Sch\u016fzka",//no i18n
"Tomorrow":"Z\u00edtra",//no i18n
"crm.gdpr.notavailable.field":"Nen\u00ed dostupn\u00fd",//no i18n
	"crm.setup.system.ziasimilarity":"Doporu\u010den\u00ed podobnosti",//no i18n
	"crm.gdpr.notavailable.field":"Nen\u00ed dostupn\u00fd",//no i18n
	"crm.label.simply.in":"v",//no i18n
	"crm.filter.label.all.products":"V\u0161e {0}",//NO I18N
	'crm.zia.nbx.filter.due' : "Due", //NO I18N
	"abm.segment": "\u201eSegment\u201c", // NO I18N
	"abm.segment.names": "\u201eN\u00e1zvy segment\u016f\u201c", // NO I18N
	"abm.abm.segment.name": "\u201eN\u00e1zev segmentu ABM\u201c", // NO I18N
	"abm.segmentation.techniques": "\u201eTechniky segmentace ABM\u201c", // NO I18N
	"abm.filters": "\u201eFiltry ABM\u201c", // NO I18N
	"abm.filters.info": "\u201eTyto filtry zobraz\u00ed informace na z\u00e1klad\u011b {0} p\u0159i\u0159azen\u00e9ho k {1}.\u201c", // NO I18N
	"abm.rfm.label": "\u201e\u0160t\u00edtek RFM\u201c", // NO I18N
	"abm.firmographics.label": "\u201eFirmografick\u00fd \u0161t\u00edtek\u201c" ,// NO I18N
	"abm.recommendation.label": "\u201eDoporu\u010duj\u00edc\u00ed \u0161t\u00edtek\u201c", // NO I18N
	"abm.voc.label": "\u201e\u0160t\u00edtek VOC\u201c", // NO I18N
	"abm.engagement.label": "\u201e\u0160t\u00edtek anga\u017eovanosti\u201c", // NO I18N
	"abm.overall.score": "\u201eCelkov\u00e9 sk\u00f3re\u201c", // NO I18N
	"abm.mood.score": "\u201eSk\u00f3re n\u00e1lady\u201c", // NO I18N
	"abm.value.score": "\u201eHodnotov\u00e9 sk\u00f3re\u201c", // NO I18N
	"abm.engagement.score": "\u201eSk\u00f3re anga\u017eovanosti\u201c", // NO I18N
	"abm.rfm.score": "\u201eSk\u00f3re RFM\u201c", // NO I18N
	"crm.customview.pin.column":"P\u0159ipnout sloupec",//no i18n
"crm.customview.unpin.column":"Odepnout sloupec",//no i18n
  "crm.column.list.max.group.limit" : "Byl dosa\u017een maxim\u00e1ln\u00ed limit.", //no i18n
	"crux.custom.field.greater.than.equalto":"{0} musí být větší nebo rovno {1}.",
	"crux.users.selected.plural" : "Počet vybraných uživatelů: {0}.",
	"crux.user.selected.singular" :"{0} vybraný uživatel.",
	"crux.criteria.empty.secondaryfield.module" : "V modulu {1} nebylo nalezeno žádné odpovídající pole {0}",
	"crux.criteria.empty.secondaryfield" : "Žádné jiné pole {0} není k dispozici pro porovnání, zadejte hodnotu pro porovnání.",
	"crux.logged.in.role.definition" : "Role uživatele, který iniciuje akce záznamu",
	"zia.similarity.smartfilter.score":"Sk\u00f3re podobnosti",//no i18n
	"zia.similarity.smartfilter.records":"Podobn\u00fd {0} z",//no i18n
	"zia.similarity.smartfilter.records.search":"Zobrazit podobn\u00fd {0} z",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"Nelze vybrat v\u00edce ne\u017e {0} {1}",//no i18n
	"crux.custom.field.greater.than.equalto":"{0} musí být větší nebo rovno {1}.",
	"crux.users.selected.plural" : "Počet vybraných uživatelů: {0}.",
	"crux.user.selected.singular" :"{0} vybraný uživatel.",
	"crux.criteria.empty.secondaryfield.module" : "V modulu {1} nebylo nalezeno žádné odpovídající pole {0}",
	"crux.criteria.empty.secondaryfield" : "Žádné jiné pole {0} není k dispozici pro porovnání, zadejte hodnotu pro porovnání.",
	"crux.logged.in.role.definition" : "Role uživatele, který iniciuje akce záznamu",
	"crux.max.limit.unselect" : "Nemůžete zrušit výběr více než {0} {1}.", //No I18N
	"crux.existing.tag" : "\u201e{0}\u201c je již vybráno", //NO I18N
	"crm.label.true" : "True",//NO I18N
	"crm.label.false" : "False",//NO I18N
	"crm.record.locked" : "Locked",//NO I18N
	"crm.filter.number.range":"hodnoty z {0}-{1}",//no i18n
	"crm.smartfilter.picklist.options.msg":"Tuto mo\u017enost nelze vybrat pro v\u00edce ne\u017e 10 vybran\u00fdch hodnot.",//no i18n
	"crm.chosen.maximum.campaigns.selected":"Nem\u016f\u017eete vybrat v\u00edce ne\u017e 5 {0}",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"Nelze vybrat v\u00edce ne\u017e {0} {1}",//no i18n
	"crm.best.time.column.label":"Nejlep\u0161\u00ed doba pro kontaktov\u00e1n\u00ed",//no i18n
	"crm.competitoralert.mentioned.in.email":"Zm\u00edn\u011bno v <b>E-mailu</b>",//no i18n
	"crm.competitoralert.with.sentiment":"Se sentimentem",//no i18n
	"crm.competitoralert.name.error":"Zadejte platnou hodnotu pro n\u00e1zev konkurenta.",//no i18n
	"crm.competitoralert.date.error":"V e-mailu uve\u010fte platn\u00e9 datum zm\u00ednky o konkurentovi.",//no i18n
	"crm.competitoralert.sentiment.error":"Vyberte pros\u00edm platnou mo\u017enost pro sentiment konkurenta.",//no i18n
	"crm.competitor.name":"Konkurent",
"crm.lookup.advance.error.msg" : "B\u011bhem aplikace filtru do\u0161lo k chyb\u011b. Obnovte str\u00e1nku a zkuste to znovu.",
	"crm.label.add.tags":"P\u0159idat zna\u010dky",//no i18n
	"crm.confirm.deassociate":"Zru\u0161it p\u0159i\u0159azen\u00ed",//no i18n
	"Contact\ Name" : "N\u00e1zev {0}",
	"Lead\ Name" : "N\u00e1zev {0}",
	"-None-" : "\u017d\u00e1dn\u00e9",
	"January" : "Leden",
	"February" : "\u00danor",
	"March" : "B\u0159ezen",
	"April" : "Duben",
	"May" : "Kv\u011bten",
	"June" : "\u010cerven",
	"July" : "\u010cervenec",
	"August" : "Srpen",
	"September" : "Z\u00e1\u0159\u00ed",
	"October" : "\u0158\u00edjen",
	"November" : "Listopad",
	"December" : "Prosinec",
	// Image Upload literals
	"crm.label.browse.files":"Proch\u00e1zet soubory",//no i18n
	"crm.label.lowercase.or":"nebo",//no i18n
	"crm.image.header":"Obr\u00e1zek",//no i18n
	"crm.image.n.n":"Pom\u011br N:N",//no i18n
	"crm.image.2.2":"Pom\u011br 2:2",//no i18n
	"crm.image.4.3":"Pom\u011br 4:3",//no i18n
	"crm.image.16.9":"Pom\u011br 16:9",//no i18n
	"crm.image.height.width.swap":"Zm\u011bna v\u00fd\u0161ky a \u0161\u00ed\u0159ky",//no i18n
	"crm.image.rotate.image":"Oto\u010dit",//no i18n
	"crm.label.lowercase.of":"z",//no i18n	
	"crm.image.empty.name.placeholder" : "Zadejte jm\u00e9no",
	"crm.image.crop.and.rotate" : "O\u0159\u00edznout a oto\u010dit",
	"crm.view.attachment.download" : "St\u00e1hnout",
	"crm.label.edit" : "Upravit",
	"crm.label.close" : "Zav\u0159\u00edt",
	"crm.label.any" : "Libovoln\u00e9",
	"crm.image.description" : "P\u0159idat popis...",
	"crm.image.zoom.in" : "P\u0159ibl\u00ed\u017eit",
	"crm.image.zoom.out" : "Odd\u00e1lit",
	"crm.image.actual.size" : "Skute\u010dn\u00e1 velikost",
	"crm.image.reset" : "Resetovat",
	"crm.label.lowercase.of" : "z",
	"crm.image.width.px" : "px",
	"crm.image.desc.maxsize" : "Popis nesm\u00ed b\u00fdt del\u0161\u00ed ne\u017e 255 znak\u016f.",
	"crm.image.name.maxsize" : "N\u00e1zev nesm\u00ed b\u00fdt del\u0161\u00ed ne\u017e 100 znak\u016f.",
	"crm.label.readonly" : "Pouze \u010cten\u00ed",
	"crm.fileuploader.message.totalfilesizeexceeded":"Celkov\u00e1 velikost soubor p\u0159esahuje povolen\u00fd limit {0}.",//no i18n
	"crm.fileuploader.message.maxfilesexceeded":"M\u016f\u017eete nahr\u00e1t maxim\u00e1ln\u011b {0} soubor\u016f.",//no i18n
	"crm.fileuploader.message.maxfilesexceeded1":"M\u016f\u017eete p\u0159ipojit maxim\u00e1ln\u011b {0} soubor\u016f.",//no i18n
	"crm.ImageuploadField.size.limit":"Celkov\u00e1 velikost obr\u00e1zk\u016f p\u0159esahuje povolen\u00fd limit {0} MB.",//no i18n
	"crm.attachment.imageUploadField.Minlen.check":"M\u016f\u017eete si vybrat pouze 1 obr\u00e1zek",//no i18n
	"crm.image.crop.error":"Nelze o\u0159\u00edznout obr\u00e1zek; zkuste tuto akci prov\u00e9st znovu!",//no i18n
	"crm.attach.here":"zde.",//no i18n
	"crm.image.unsupported.corrupted.single":"Fotografie, kterou se pokou\u0161\u00edte nahr\u00e1t, je po\u0161kozen\u00e1.",//no i18n
	"Browse":"Browse",//no i18n
	"crm.imageupload.failure":"N\u011bkter\u00e9 obr\u00e1zky nelze nahr\u00e1t. Zkuste to znovu pozd\u011bji.",//no i18n
	"zc.editor.width":"\u0160\u00ed\u0159ka",//no i18n
	"ze.editor.height":"V\u00fd\u0161ka",//no i18n
	"crm.label.delete":"Odstranit",//no i18n
	"crm.image.error.gif":"Obr\u00e1zek GIF nelze o\u0159\u00edznout.",//no i18n
	"crm.fileuploader.message.morefiles":"Ostatn\u00ed soubory {0}",//no i18n
	"crm.fileuploader.message.invalidfileType1":"Soubor {0} nen\u00ed podporov\u00e1n jako p\u0159\u00edloha.",//no i18n
	"crm.fileuploader.message.invalidfileType2":"Soubory {0} a {1} nejsou podporov\u00e1ny jako p\u0159\u00edlohy.",//no i18n
	"crm.attach.upload.sizelimit":"Limit celkov\u00e9 velikosti",//no i18n
	
	
	

	
	"crm.fileuploader.message.maxfilesexceeded" : "M\u016f\u017eete nahr\u00e1t maxim\u00e1ln\u011b {0} soubor\u016f.",
	"crm.file.upload.maxlength.exceeds" : "M\u016f\u017eete nahr\u00e1t pouze jeden soubor v {0}."

}
